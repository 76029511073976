import React, { useState, useEffect } from "react";
import axios from "axios";
import * as Yup from "yup";
import { Form, Formik, Field } from "formik";
import Layout from "../../Components/Layout";
import Box from "../../Components/Box2";
import Input from "../../Components/Input";
import Checkbox from "../../Components/Checkbox";
import { RaisedButton } from "../../Components/Buttons";
import 'react-datepicker/dist/react-datepicker.css';
import CreatableSelect from 'react-select/creatable';

function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test: function(value) {
      return value === this.resolve(ref)
    }
  })
};

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Laukas yra privalomas"),
  surname: Yup.string().required("Laukas yra privalomas"),
  email: Yup.string()
    .email("Neteisingas el.pašto formatas")
    .required("Laukas yra privalomas"),
  emailrep: Yup.string().required("Laukas yra privalomas").equalTo(Yup.ref('email')),
  //schoolSel: Yup.string().required("Laukas yra privalomas"),
  rules: Yup.bool(true).oneOf([true]),
  privacy: Yup.bool(true).oneOf([true]),
})



export default () => {

  const [data, setData] = useState([]);

  const [inputValueMok, setInputValueMok] = useState('');

  const [serverResponse, setServerResponse] = useState(null)

  const [value2, setValue2] = useState("no");

  const onSubmit = values => {
    axios
      .post(
        "https://europosegzaminas.lrt.lt/euegzaminas/api2/registerme",
        new URLSearchParams({
          ...values,
          rules: values.rules ? 1 : 0,
          privacy: values.privacy ? 1 : 0,
          type: value2,
          schoolSel: values.schoolSel != null ? values.schoolSel.value : "",
        }).toString(),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(response => {
        if (response.data.status === "error") {
          setServerResponse(response.data.message)
        } else {
          window.location = "/egzaminas/begin?t=" + response.data.guid;
        }
      })
  }

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    //if (inputValueMok) {
      fetch(`https://europosegzaminas.lrt.lt/euegzaminas/api/search2`)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          const formattedData = result.map(item => ({
            value: item.id,
            label: item.name
          }));
          setData(formattedData);
        })
        .catch(error => console.log('Error fetching data:', error));
    //}
  }, [inputValueMok]);

  return (
    <Layout>
      <div className="registration-page">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            name: "",
            surname: "",
            email: "",
            emailrep: "",
            rules: false,
            privacy: false,
            selectedItem: '',
            schoolSel: ''
          }}
          onSubmit={onSubmit}
          component={( {setFieldValue} ) => (
            <Form>
              {/*<FormObserver  //onSubmit={(e) => e.preventDefault()} />*/}
              <Box>
                <h3 className="tac">REGISTRACIJA</h3>

                <Field type="hidden" name="type" value="0"/>


                <h5 className="tac block-header-left-r">Pasirink dalyvio kategoriją</h5>

                <div className="registration-page--form-row">

                  <div className="box" style={{padding:"0px"}}>

                  <input type="radio" name="tipas" aria-label="idividualiai" id="option-1" value="1" checked={value2 === "1"} onChange={(e) => setValue2(e.currentTarget.value)}/>
                  <input type="radio" name="tipas" aria-label="5-8 klase" id="option-2" value="2" checked={value2 === "2"} onChange={(e) => setValue2(e.currentTarget.value)}/>
                  <input type="radio" name="tipas" aria-label="9-12 klase" id="option-3" value="3" checked={value2 === "3"} onChange={(e) => setValue2(e.currentTarget.value)}/>


                          <label htmlFor="option-1" className="option-1">
                            <div className="dot"></div>
                            <div className="text">
                              <strong id="CTA">INDIVIDUALŪS DALYVIAI</strong>
                              <p id="body">Nuo studentų iki senjorų – ši kategorija skirta plačiajai visuomenei</p>
                            </div>
                          </label>


                          <label htmlFor="option-2" className="option-2">
                            <div className="dot"></div>
                            <div className="text">
                              <strong id="CTA">5-8 KLASIŲ MOKINIAI</strong>
                              <p id="body">Ši kategorija skirta 5-8 kl. moksleiviams</p>
                            </div>
                          </label>
                          <label htmlFor="option-3" className="option-3">
                            <div className="dot"></div>
                            <div className="text">
                              <strong id="CTA">I-IV GIMNAZINIŲ KLASIŲ MOKINIAI</strong>
                              <p id="body">Ši kategorija skirta I-IV gimnazinių klasių moksleiviams</p>
                            </div>
                          </label>
                  </div>
                </div>



                {(value2 !== "no") && (
                <div>

                <h5 className="tac block-header-left-r">Informacija</h5>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="name"
                    label="Vardas"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="surname"
                    label="Pavardė"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="email"
                    label="El. pašto adresas"
                  />
                </div>

                <div className="registration-page--form-row">
                  <Field
                    component={Input}
                    type="text"
                    placeholder=""
                    required
                    name="emailrep"
                    label="Pakartok el. pašto adresą"
                  />
                </div>

                  {(value2 === "2" || value2 === "3") && (

                    <div className="registration-page--form-row">
                      <div style={{width:"100%", height: "60px"}}>

                        <label className="labelReg">Mokykla<sup style={{fontSize:"18px", top:"0"}}>*</sup></label>


                        <Field name="schoolSel">
                          {({ field, meta, form: { setFieldValue } }) => {
                            return (
                              <CreatableSelect
                                {...field}
                                options={data}
                                className="selectNae"
                                classNamePrefix="selectNaesel"
                                onChange={val => {
                                  setFieldValue("schoolSel", val);
                                  setSelectedValue(val);
                                  console.warn(val.value);
                                }}
                                placeholder={<div id="PlaceholderCol">Pasirinkite arba įveskite...</div>}
                      isClearable
                      formatCreateLabel={(inputValue) => `Įvesti pavadinimą: "${inputValue}"`}
                              />
                            );
                          }}
                        </Field>
                      </div>
                   </div>)}


                <div className="registration-page--form-row">
                  <Field component={Checkbox} name="privacy">
                    <div>
                      Susipažinau su {" "}
                    <a
                      href="/privatumas"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{color:"#EF6C00"}}
                    >
                      privatumo taisyklėmis*
                    </a>
                    
                    </div>
                  </Field>
                </div>

                <div className="registration-page--form-row">
                  <Field component={Checkbox} name="rules">
                    <div>
                      Sutinku su {" "}
                      <a
                      href="/taisykles"
                      rel="noopener noreferrer"
                      target="_blank"
                      style={{color:"#EF6C00"}}
                    >
                      konkurso taisyklėmis.*
                    </a>
                    </div>
                  </Field>
                </div>


                </div>)}

    
                {serverResponse && (
                  <div className="registration-page--server-message">
                    {serverResponse}
                  </div>
                )}
              

              {(value2 !== "no") && (
              <div className="tac">
                <RaisedButton variant="blue" type="submit">Pradėti egzaminą</RaisedButton>
              </div>
              )}

            </Box>
            </Form>
          )}
        />
      </div>
      
    </Layout>
  )
}
